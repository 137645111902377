import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { SHOPPING_CART } from '../../../constants/routes';
import { authenticationSelector } from '../../../redux/selectors/auth.selector';
import { orderSelector } from '../../../redux/selectors/order.selector';
import { orderConfirmationSelector } from '../../../redux/selectors/orderConfirmation.selector';
import { useSeoState } from '../../../utils/hooks/seo-state/seo-state';
import { IProductPrice, ProductUtility } from '../../../utils/product-utility';
import { checkMembership, checkTruthy } from '../../../utils/utils';
import { IProductListContents } from '../../../_foundation/interface/ProductList/IProductList';
import {
  ICartContentsItem,
  IMerchandisingAssociations,
} from '../../../_foundation/interface/Responses/ICartProductsResponse';
import { ProductPricingConstants } from '../../Widgets/ShoppingCart/CartItems/ProductPricing/ProductPricing.constants';
import { usePricingCall } from '../PricingCall/PricingCall';

interface IProcessPricing {
  productCard:
    | ICartContentsItem
    | IProductListContents
    | IMerchandisingAssociations;
  setUnitPrice?: (unitPrice: number) => void;
  inViewport: boolean;
  isRvi?: boolean;
}

const useProcessPricing = ({
  productCard,
  setUnitPrice,
  inViewport,
  isRvi,
}: IProcessPricing) => {
  const { CATALOG_ORDER_FORM } = ProductPricingConstants;

  const history = useHistory();

  const isCartPage = history.location.pathname === SHOPPING_CART;

  const { extendedOfferResponse, extendedPriceLoading, callPricingAPI } =
    usePricingCall({
      productCard,
      inViewport,
    });

  const { userType } = useSelector(authenticationSelector);

  const { orderItem: orderConfirmationItems } = useSelector(
    orderConfirmationSelector
  );

  const { orderItem } = useSelector(orderSelector);

  const [productPrice, setProductPrice] = useState<IProductPrice>();

  const [unitPriceCart, setUnitPriceCart] = useState<string>('');

  const [individualItemPrice, setIndividualItemPrice] = useState<string>('');

  const [itemFromBulkOrder, setItemFromBulkOrder] = useState(false);

  const showSavings = isCartPage ? isCartPage : !itemFromBulkOrder;

  const [quantity, setQuantity] = useState<string>('1');

  const { isMembership } = checkMembership(userType);

  const { isPdpPage } = useSeoState();

  const initPricingData = useCallback((): void => {
    if (!extendedPriceLoading && extendedOfferResponse && callPricingAPI) {
      const productPrice = ProductUtility.processPrice({
        productDetails: productCard,
        extendedOfferResponse,
        isMembership,
        unitPriceCart: Number(unitPriceCart),
        isCartPage,
        quantity: Number(quantity),
        ...{
          ...(isPdpPage && {
            quantity: 1,
          }),
        },
        individualProductPrice: Number(individualItemPrice),
      });

      setProductPrice(productPrice);
    } else if (!callPricingAPI) {
      const productPrice = ProductUtility.processPrice({
        productDetails: productCard,
        isMembership,
        isCartPage,
        ...{
          // Added isCartPage check to send the quantity value to utility only in cart page
          ...(isCartPage
            ? {
                unitPriceCart: Number(unitPriceCart),
                quantity: Number(quantity),
              }
            : { quantity: 1 }),
        },
        // TODO: Remove below code block
        // This code block is redundant now due to the logic implemented above
        // leaving this here to avoid complicating the hotfix
        ...{
          ...(isPdpPage && {
            quantity: 1,
          }),
        },
        individualProductPrice: Number(individualItemPrice),
        isRvi: isRvi,
      });

      setProductPrice(productPrice);
    }
  }, [
    extendedPriceLoading,
    extendedOfferResponse,
    callPricingAPI,
    productCard,
    isMembership,
    unitPriceCart,
    isCartPage,
    quantity,
    isPdpPage,
    individualItemPrice,
    isRvi,
  ]);

  useEffect(() => {
    initPricingData();
  }, [initPricingData]);

  /**
   * @callback initCartQuantity Retrives the cart
   * item's quantity from orderitem's response.
   */
  const initCartQuantity = useCallback((): void => {
    /** #92470
     * Added check for isCartPage as price should be calculated only in cart page
     * and without this check the price was getting calculated in PLP.
     * Add else condition and reseting the state just in case previous state value persists.
     */
    if (orderItem.length !== 0 && isCartPage) {
      let currentItemQuantity = orderItem.filter(
        ({ partNumber, freeGift }) =>
          partNumber === productCard.partNumber && !checkTruthy(freeGift)
      )[0];
      /* const currentItemQuantitylength = orderItem.filter(
        ({ partNumber }) => partNumber === productCard.partNumber
      );*/

      if (currentItemQuantity?.orderItemPrice.length > 0) {
        setUnitPriceCart(currentItemQuantity?.orderItemPrice);
        setIndividualItemPrice(currentItemQuantity.unitPrice);
      }
      currentItemQuantity?.quantity.length > 0 &&
        setQuantity(currentItemQuantity?.quantity);

      if (
        currentItemQuantity?.orderItemExtendAttribute &&
        currentItemQuantity?.orderItemExtendAttribute.length > 0
      ) {
        const fromCatalogOrderFormAttr =
          currentItemQuantity?.orderItemExtendAttribute.find(
            ({ attributeName }) =>
              attributeName.toLowerCase() === CATALOG_ORDER_FORM.toLowerCase()
          );

        if (fromCatalogOrderFormAttr) {
          if (checkTruthy(fromCatalogOrderFormAttr.attributeValue)) {
            setItemFromBulkOrder(true);
          }
        }
      }
    } else {
      setQuantity('1');
      setUnitPriceCart('');
    }

    if (orderConfirmationItems.length !== 0) {
      const currentItemQuantity = orderConfirmationItems.filter(
        ({ partNumber }) => partNumber === productCard.partNumber
      )[0];

      if (currentItemQuantity?.orderItemPrice.length > 0) {
        setUnitPriceCart(currentItemQuantity?.orderItemPrice);
        setIndividualItemPrice(currentItemQuantity.unitPrice);
      }
      if (
        currentItemQuantity?.orderItemExtendAttribute &&
        currentItemQuantity?.orderItemExtendAttribute.length > 0
      ) {
        const fromCatalogOrderFormAttr =
          currentItemQuantity?.orderItemExtendAttribute.find(
            ({ attributeName }) =>
              attributeName.toLowerCase() === CATALOG_ORDER_FORM.toLowerCase()
          );

        if (fromCatalogOrderFormAttr) {
          if (checkTruthy(fromCatalogOrderFormAttr.attributeValue)) {
            setItemFromBulkOrder(true);
          }
        }
      }
    }
  }, [
    orderItem,
    isCartPage,
    orderConfirmationItems,
    productCard.partNumber,
    CATALOG_ORDER_FORM,
  ]);

  useEffect(() => {
    initCartQuantity();

    if (setUnitPrice && productPrice && productPrice.unitPrice) {
      setUnitPrice(productPrice.unitPrice);
    }
  }, [initCartQuantity, setUnitPrice, productPrice]);

  return {
    productPrice,
    showSavings,
    quantity,
    extendedPriceLoading,
  };
};

export { useProcessPricing };
