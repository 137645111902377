import { Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  CHECKOUT,
  ORDER_CONFIRMATION,
  SHOPPING_CART,
} from '../../../constants/routes';
import { useSeoState } from '../../../utils/hooks/seo-state/seo-state';
import { IProductPrice } from '../../../utils/product-utility';
import { checkTruthy, formatPrice } from '../../../utils/utils';
import { IProductListContents } from '../../../_foundation/interface/ProductList/IProductList';
import {
  ICartContentsItem,
  IMerchandisingAssociations,
} from '../../../_foundation/interface/Responses/ICartProductsResponse';
import { Badge } from '../../Widgets/Badge/Badge';
import { ProductCardSectionMessagesConstants } from '../../Widgets/Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';
import { ProductPricingConstants } from '../../Widgets/ShoppingCart/CartItems/ProductPricing/ProductPricing.constants';
import { PlpPricingMessage } from '../PlpPricingMessage/PlpPricingMessage';
import { QuantityPricing } from '../QuantityPricing/QuantityPricing';

interface IProductPricing {
  productCard:
    | ICartContentsItem
    | IProductListContents
    | IMerchandisingAssociations;
  setUnitPrice?: (unitPrice: number) => void;
  productPrice?: IProductPrice;
  showSavings: boolean;
  quantity: string;
  showQtyPopper?: boolean;
  isCartCard?: boolean;
  isCompareProduct?: boolean;
  isWishlistCard?: boolean;
  requestedQuantity?: string;
}

const ProductOnSalePrice: React.FC<IProductPricing> = ({
  productCard,
  productPrice,
  showSavings,
  quantity,
  showQtyPopper,
  isCartCard,
  isCompareProduct,
  isWishlistCard,
  requestedQuantity,
}) => {
  const {
    SALE_PRICE_CART,
    QUANTITY_PRICING,
    REG,
    SAVE,
    REGULAR_PRICE,
    ORDER_TODAY,
  } = ProductCardSectionMessagesConstants;

  const { EACH } = ProductPricingConstants;

  const { isPdpPage } = useSeoState();

  const formattedDisplayPriceEach =
    formatPrice(
      productPrice?.productUnitPrice
        ? productPrice.productUnitPrice.toString()
        : ''
    ) + EACH;

  const history = useHistory();

  const { t } = useTranslation();

  const showSeeCartPriceMsg = checkTruthy(productCard.salesPriceRestricted);

  const isCartPage = history.location.pathname === SHOPPING_CART;

  const isCheckoutPage = history.location.pathname.includes(CHECKOUT);

  const isOrderConfirmation =
    history.location.pathname.includes(ORDER_CONFIRMATION);

  return (
    <>
      {!productPrice?.swatchable && (
        <div
          className={`price-cell ${
            isCartPage && !isWishlistCard ? 'price-cell-review' : ''
          }`}>
          {showSeeCartPriceMsg && !isCartPage && (
            <div className='nte-price24 price product-offer-price offer-price'>
              {t(SALE_PRICE_CART)}
            </div>
          )}

          {(isCartPage && !isPdpPage) || !showSeeCartPriceMsg ? (
            <div className='nte-price24 price product-offer-price offer-price'>
              {productPrice?.formattedOfferPrice}
              {isWishlistCard &&
                requestedQuantity &&
                +requestedQuantity > 1 &&
                EACH}
            </div>
          ) : (
            <>
              {(!showSeeCartPriceMsg || isCartPage) && (
                <div className='nte-price24 price product-offer-price offer-price'>
                  {formatPrice(
                    productPrice?.unitPrice
                      ? productPrice.unitPrice.toString()
                      : ''
                  )}
                </div>
              )}
            </>
          )}

          <PlpPricingMessage productPrice={productPrice} />

          <QuantityPricing
            productPrice={productPrice}
            showSavings={showSavings}
          />

          {Number(productPrice?.minimumQuantity) && showSavings ? (
            <p className='sub-product-offer-price pxs min-quantity'>
              {t(QUANTITY_PRICING, {
                minimumQuantity: productPrice?.minimumQuantity,
                minimumOfferPrice: productPrice?.minimumOfferPrice,
              })}
            </p>
          ) : (
            <>
              {!productPrice?.cartQtyMessage &&
                showSavings &&
                !showSeeCartPriceMsg && (
                  <>
                    <Grid
                      container
                      className='sub-product-offer-price pxs reg-save-pricing'
                      justifyContent='center'
                      // For RVI component align the price to the center
                      {...(showQtyPopper && { justifyContent: 'center' })}
                      // For PDP and PLP cards align the price to the start
                      {...(!showQtyPopper && { justifyContent: 'flex-start' })}
                      // For Checkout pages and order confirmation pages pricing should be aligned to flex end
                      {...(showQtyPopper &&
                        isCompareProduct && { justifyContent: 'flex-start' })}
                      {...(!showQtyPopper &&
                        isCompareProduct && { justifyContent: 'flex-start' })}
                      {...(!showQtyPopper &&
                        (isCheckoutPage || isOrderConfirmation) && {
                          justifyContent: 'flex-end',
                        })}
                      // For the cart page align the price to the end
                      {...(isCartCard && { justifyContent: 'flex-end' })}
                      {...(isCompareProduct
                        ? { alignItems: 'flex-start' }
                        : { alignItems: 'center' })}>
                      <span className='strike-price'>
                        {t(REG, {
                          formattedDisplayPrice:
                            Number(quantity) !== 1
                              ? formattedDisplayPriceEach
                              : formattedDisplayPriceEach.slice(0, -4),
                        })}
                      </span>

                      <Badge
                        className='save-price-badge'
                        label={t(SAVE, {
                          saveAmount: productPrice?.saveAmount,
                        })}
                        removeDefaultBadge
                      />
                    </Grid>

                    <p className='sub-product-offer-price pxs color-brown order-today field-margin'>
                      {t(ORDER_TODAY)}
                    </p>
                  </>
                )}

              {!productPrice?.cartQtyMessage &&
                !isCartPage &&
                showSeeCartPriceMsg &&
                showSavings && (
                  <p className='sub-product-offer-price pxs reg-save-pricing'>
                    {t(REGULAR_PRICE, {
                      formattedDisplayPrice:
                        productPrice?.formattedDisplayPrice,
                    })}
                  </p>
                )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export { ProductOnSalePrice };
