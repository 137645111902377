import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_PRODUCT_EXTENDED_PRICING_ACTION } from '../../../redux/actions/extendedPricing.action';
import { authenticationSelector } from '../../../redux/selectors/auth.selector';
import { productDetailSelector } from '../../../redux/selectors/productDetail.selector';
import { checkMembership, checkTruthy } from '../../../utils/utils';
import {
  IPricingPayload,
  PricingService,
} from '../../../_foundation/apis/pricing/pricing.service';
import { useSite } from '../../../_foundation/hooks/usesite/useSite';
import { IPricing } from '../../../_foundation/interface/Pricing/IPricing';
import { IProductListContents } from '../../../_foundation/interface/ProductList/IProductList';
import {
  ICartContentsItem,
  IMerchandisingAssociations,
} from '../../../_foundation/interface/Responses/ICartProductsResponse';
import { PdpPageConstants } from '../../PDP/PdpPageConstants';
import { swatchSelector } from '../../Widgets/Swatch/redux/selector/swatch.selector';

interface IPricingCall {
  productCard:
    | ICartContentsItem
    | IProductListContents
    | IMerchandisingAssociations;
  inViewport: boolean;
}

const usePricingCall = ({ productCard, inViewport }: IPricingCall) => {
  const {
    ATTRIBUTES: { PRODUCT },
  } = PdpPageConstants;

  const { userType } = useSelector(authenticationSelector);

  const { productDetails } = useSelector(productDetailSelector);

  const { partNumber: selectedSku, skuProductDetails } =
    useSelector(swatchSelector);

  const dispatch = useDispatch();

  const [extendedOfferResponse, setExtendedOfferResponse] =
    useState<IPricing>();

  const [extendedPriceLoading, setExtendedPriceLoading] =
    useState<boolean>(true);

  const { mySite } = useSite();

  const { isMembership } = checkMembership(userType);

  const partNumber = productCard.partNumber;

  const extendedOffer = useMemo(
    () => productCard?.extendedOffer && checkTruthy(productCard?.extendedOffer),
    [productCard?.extendedOffer]
  );

  const extendedBaseOffer = useMemo(
    () =>
      productCard?.extendedBaseOffer &&
      checkTruthy(productCard?.extendedBaseOffer),
    [productCard?.extendedBaseOffer]
  );

  const noOfferPrices = useMemo(
    () =>
      productCard?.UserData &&
      productCard?.UserData[0].eOfferPrice.length === 0,
    [productCard?.UserData]
  );

  const callPricingAPI = useMemo(
    () =>
      (isMembership && extendedOffer) ||
      (!isMembership && extendedBaseOffer) ||
      noOfferPrices,
    [extendedBaseOffer, extendedOffer, isMembership, noOfferPrices]
  );

  /**
   * If the extendedOffer.extendedBaseOffer field is true OR if there are no
   * offer prices in the product details data, then call the offer price API.
   */
  const getExtendedPricing = useCallback(async () => {
    try {
      if (
        inViewport &&
        extendedOfferResponse?.EntitledPrice[0]?.partNumber !== partNumber
      ) {
        /**
         * Checks if the product has children
         */
        const parentChildData =
          productDetails && productDetails?.type.toLowerCase() === PRODUCT;

        /**
         * Sets the product details based on whether the sku is selected
         */
        const productInfo =
          parentChildData && selectedSku && skuProductDetails
            ? skuProductDetails
            : productDetails;

        if (mySite.storeID && productCard && callPricingAPI && partNumber) {
          const payload: IPricingPayload = {
            storeID: mySite.storeID,
            partNumbers: [partNumber],
          };

          setExtendedPriceLoading(true);

          const extendedOfferPriceResponse: IPricing =
            await PricingService.fetchPricingDetails(payload);

          setExtendedPriceLoading(false);

          if (
            productInfo.partNumber ===
            extendedOfferPriceResponse.EntitledPrice[0].partNumber
          ) {
            dispatch(
              UPDATE_PRODUCT_EXTENDED_PRICING_ACTION(extendedOfferPriceResponse)
            );
          }

          setExtendedOfferResponse(extendedOfferPriceResponse);

          setExtendedPriceLoading(false);
        }
      } else {
        setExtendedPriceLoading(false);
      }
    } catch (e) {
      setExtendedPriceLoading(false);

      console.error(e);
    }
  }, [
    PRODUCT,
    callPricingAPI,
    dispatch,
    extendedOfferResponse?.EntitledPrice,
    inViewport,
    mySite.storeID,
    partNumber,
    productCard,
    productDetails,
    selectedSku,
    skuProductDetails,
  ]);

  useEffect(() => {
    getExtendedPricing();
  }, [getExtendedPricing]);

  return { extendedOfferResponse, extendedPriceLoading, callPricingAPI };
};

export { usePricingCall };
